.success_container {
    width: 323px;
    .success_content{
        width: 100%;
        background: #FDEEEA;
        padding: 10px;
        .success_content_title{
            font-family: 'PainRegular';
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: rgba(18, 17, 17, 0.83);
            background: #FDEEEA;
            border: 2px dashed rgba(255, 180, 157, 0.56);
            box-sizing: border-box;
            padding: 20px;
            width: 100%;
            span{
                font-family: 'BigCaslonRegular';
            }
        }
    }
    .success_content_please{
        .success_content_please_title{
            font-family: 'BigCaslonRegular';
            font-size: 18px;
            line-height: 22px;
            text-align: center;
        }
        .success_content_please_under-line{
            width: 194px;
            height: 1px;
            background: rgba(255, 180, 157, 0.56);
            display: block;
            margin: 20px auto;
        }
    }

    .success_footer{
        .success_footer_title{
            font-family: 'BigCaslonRegular';
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;    
            margin: 5px 0px 20px 0px;;        
        }
        .success_footer_list{
         
            .success_footer_list_item{
                font-size: 12px;
            }
        }
    }
}
@media (min-width: 700px) and (orientation: landscape) { 
   .success{
       height: auto !important;
       padding: 40px 0;
   }
 }