.allure_log{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .allure_logo_image{
        width: 195.73px;
        height: 56.84px;
    }
    .allure_logo_text{
        width: 96px;
        height: 16.59px;
    }
}