* {
    margin: 0;
    padding: 0;
}
html,body{
    background: #fff5f2;
}
html,
body,
#root,
.allure_qr{
    height: 100%;
}

@import 'fonts/fonts';
@import './components/allureLogo';
@import './pages/app';
@import './pages/klaviyoFormPage';
@import './pages/success';