  @font-face {
    font-family: 'BigCaslonRegular';
    src: url('../../fonts/Big-Caslon-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'PainRegular';
    src: url('../../fonts/Plain-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }